.container {
  font-size: 14px;
  line-height: 24px;
  border-radius: 12px;
  border: 2px solid var(--grey-200);
  overflow-x: scroll;
  white-space: nowrap;
  background: white;
}

.scrollable {
  overflow-y: scroll;
  max-height: 500px;
}

.container::-webkit-scrollbar {
  display: none;
}

.container table {
  width: 100%;
  border-spacing: 0;
}

.header {
  background-color: var(--grey-900);
  color: white;
  border-bottom: 1px solid var(--grey-200);
  border-radius: 12px 12px 0 0;
}

.header.sticky {
  position: sticky;
  top: 0;
}

.header tr th {
  text-align: start;
  padding: 12px 24px;
}

.body {
  font-size: 14px;
  line-height: 24px;
  border-radius: 0 0 12px 12px;
}

.bodyAlternateRow tr:nth-child(even) {
  background-color: var(--grey-50);
}

.bodyAlternateCell tr td:nth-child(even) {
  background-color: #e6f7ff;
}

.body tr:not(:last-child) td {
  border-bottom: 1px solid var(--grey-200);
}

.row:hover {
  background-color: var(--grey-200);
}

.body tr td {
  padding: 12px 24px;
}

.clickable {
  cursor: pointer;
}

.disableHover:hover {
  background-color: initial;
}
