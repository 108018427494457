.form {
  display: flex;
  flex-direction: column;
  max-width: 550px;
  gap: 24px;
  padding: 24px;
  padding-top: 120px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.error {
    color: #d92d20;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    text-align: center;
}