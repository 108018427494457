/* Overlay fades in quickly */
@keyframes overlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

.modalOverlay {
  position: fixed;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 50;
  overflow: auto;
}

@supports (height: 100dvh) {
  .modalOverlay {
    height: 100dvh;
    width: 100dvw;
  }
}

.wrapper {
  transition: all 0.3s ease;
  position: relative;
  background: transparent;
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
}

.wrapper.scrollable {
  width: auto;
  height: unset;
  margin: 48px 0;
  display: block;
}

.modalOverlay.fadeIn {
  opacity: 0;
  animation: overlayFadeIn 0.2s ease-out forwards;
}

.modalOverlay.fadeOut {
  animation: fadeOut 0.3s ease-out forwards;
}

.modalContent {
  margin: 0;
  background: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 30vw;
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: visible;
}

.sidePanelWrapper {
  height: 100%;
}

@media only screen and (max-width: 750px), only screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
    margin: 0 8px;
    display: block;
  }

  .wrapper.scrollable {
    margin: 0 8px;
  }
}

.modalContent.scrollable {
  max-height: 50vh;
  overflow-y: auto;
}

.modalContent.fadeIn {
  transform: scale(0.8);
  opacity: 0;
  animation: modalFadeIn 0.3s ease-out forwards;
}

.modalContent.fadeOut {
  animation: modalFadeOut 0.3s ease-out forwards;
}

.modalOverlay.hidden {
  animation: fadeOut 0.3s ease-out forwards;
}

.modalContent.hidden {
  animation: modalFadeOut 0.3s ease-out forwards;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.sidePanel {
  background-color: white;
  min-width: 20vw;
  height: 100%;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.sidePanel.slideIn {
  animation: slideIn 0.3s ease;
}

.sidePanel.slideOut {
  animation: slideOut 0.3s ease;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey-200);
  margin-bottom: 16px;
}

.title {
  color: var(--base-black);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.56px;
}
