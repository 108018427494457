.container {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.tabContainer {
  border-radius: 53px;
  background-color: var(--grey-100);
  display: flex;
}

.tab {
  padding: 16px 24px;
  height: 52px;
  border-radius: 86px;
  gap: 8px;
  flex: 1;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  color: #667085;
  cursor: pointer;
  font-family: "Gotham";
}

.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.selected {
  font-weight: 500;
  border: 1px solid var(--grey-300);
  background-color: white;
  color: var(--grey-900);
  pointer-events: none;
}

.small .tab {
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-400);
  font-weight: 700;
  border: 1px solid transparent;
  border-radius: 0%;
  white-space: nowrap;
  padding: 0px 4px 12px 4px !important;
  height: unset;
}

.small .selected {
  background-color: transparent;
  border-bottom: 2px solid var(--grey-900);
  color: var(--grey-900);
}

.small .tabContainer {
  background-color: transparent;
  width: fit-content;
  gap: 12px;
}
