:root {
  --base-black: #242424;

  --grey-900: #1c1c1c;
  --grey-700: #2b2b2b;
  --grey-600: #475569;
  --grey-500: #43545f;
  --grey-400: #98a2b3;
  --grey-300: #d0d5dd;
  --grey-200: #eaecf0;
  --grey-150: #f2f2f2;
  --grey-100: #f2f4f7;
  --grey-50: #f9fafb;
}

body {
  position: relative;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Gotham";
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/Gotham/Gotham-Book.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/Gotham/GothamMedium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/Gotham/GothamBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/Gotham/GothamBlack.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/Gotham/GothamUltra.ttf");
  font-weight: 800;
}

h1 {
  font-weight: 500;
  font-size: 36px;
}

main {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

select {
  appearance: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

#root {
  height: 100%;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--white);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--base-black);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--grey-700);
  }
}

a {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

select::-ms-value {
  background: none;
}

button {
  background-color: var(--white);
  border: 0px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--grey-200);
  margin: 1em 0;
  padding: 0;
}

.weglot_here {
  position: absolute;
  bottom: 0;
  right: 20%;
}
