.referenceContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.referenceColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.referenceLabel {
  color: black;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.name {
  color: var(--grey-400);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 180px;
  white-space: pre-wrap;
}

.actionContainer {
  display: flex;
  justify-content: end;
  align-items: center;
  border-radius: 32px;
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.actionButton:hover {
  background-color: var(--grey-200);
  cursor: pointer;
}

.actionButton:active {
  background-color: var(--grey-300);
}
