.vertical {
  display: block;
  width: 1px;
  height: 100%;
  background: var(--grey-200);
}

.horizontal {
  display: block;
  width: 100%;
  height: 1px;
  background: var(--grey-200);
}
