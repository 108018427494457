.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: var(--grey-50);
}

.container {
  flex: 1;
  gap: 40px;
  padding: 30px;
  overflow-y: auto;
}

.header {
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--grey-200);
}

.section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 0;
}

.separator {
  border-bottom: 1px solid var(--grey-200);
}

.totals {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  border-top: 1px solid var(--grey-200);
  gap: 20px;
}

.totalsCard {
  flex: 1;
  width: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 35px;
  border-radius: 12px;
  background-color: var(--grey-200);
}

.editButton {
  width: fit-content;
  padding: 0 20px;
  line-height: unset;
  background-color: var(--grey-300);
  border-radius: 40px;
  cursor: pointer;
}

.totalsCard p {
  font-size: 14px;
  font-weight: 500;
  color: var(--grey-800);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid var(--grey-200);
  gap: 20px;
  background-color: white;
}

.disbursementSection {
  margin-top: 40px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.cashDisbursementCard {
  width: 60%;
  min-width: 300px;
  display: grid;
  gap: 40px;
  padding: 15px 20px;
  background-color: white;
  border: 1px solid var(--grey-200);
  border-radius: 12px;
}

.disbursementAmount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disbursementJustification {
  display: grid;
  gap: 20px;
}

.cashDisbursementCard p {
  font-size: 16px;
  font-weight: 00;
  color: var(--grey-700);
}
