.container {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.card {
  padding: 5px 20px;
  border-radius: 12px;
  background-color: white;
  border: 1px solid var(--grey-200);
  display: flex;
  gap: 50px;
  text-wrap: nowrap;
}

.column {
  display: flex;
  flex-direction: column;
}

.separator {
  border-bottom: 1px solid var(--grey-200);
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 15px 0;
}

.line p {
  font-size: 14px;
  font-weight: 500;
  color: var(--grey-900);
}
