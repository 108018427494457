.container {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.inputContainer {
  display: flex;
  position: relative;
}

.input {
  padding: 16px 24px;
  border: 1px solid var(--grey-300);
  gap: 8px;
  border-radius: 40px;
  height: 56px;
  line-height: 24px;
  width: 100%;
  font-size: 16px;
}

.password {
  letter-spacing: 2px;
}

.eyeIcon {
  position: absolute;
  right: 32px;
  top: 20px;
  cursor: pointer;
}

.eyeIcon:hover {
  opacity: 0.8;
}
