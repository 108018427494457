.fileInputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hiddenInput {
  display: none;
}

.customButton {
  background-color: black;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.customButton:hover {
  background-color: #333;
}

.fileName {
  font-size: 14px;
  color: #333;
}
