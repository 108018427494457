.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  flex: 1;
}

.selectContainer {
  display: flex;
  width: 100%;
  position: relative;
}

.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.select {
  border-radius: 40px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;

  /* Arrow icon */
  padding-right: 42px !important;
}

.option {
  /* Font */
  font-family: "Manrope";
  font-size: 16px;
  color: var(--base-black);
  width: 100%;
}

.placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
}

.smallerSides {
  padding-right: 30px !important;
  padding-left: 10px !important;
}

.arrowDownIcon {
  position: absolute;
  right: 0px;
  transform: translate(-25px, 23px);
  z-index: 5;
}

.reduced {
  transform: translate(-10px, 25px) !important;
}

.normal .select {
  padding: 16px 24px 16px 24px;
  height: 56px;
  border: 1px solid var(--grey-300);
  font-weight: 500;
  gap: 8px;
}

.small .select {
  padding: 2px 16px;
  height: auto;
  border: 1px solid transparent;
  font-weight: 400;
}

.small .arrowDownIcon {
  transform: translate(-25px, 12px);
}
