.topBar {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  height: 60px;
  gap: 8px;
  border-bottom: 1px solid var(--grey-200);
  align-items: center;
}

.titleContainer {
  display: flex;
  gap: 8px;
}

.title {
  line-height: 36px;
  color: var(--base-black);
  font-size: 24px;
  font-weight: 700;
}

.timeLabel {
  color: #667085b2;
  font-family: "Hanken Grotesk", sans-serif;
  line-height: 24px;
  font-weight: 600;
  width: 90px;
  text-align: right;
}
