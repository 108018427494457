.wrapper {
  height: 100%;
  width: 100%;
  background: var(--grey-50);
}

.container {
  height: 100%;
  gap: 40px;
  padding: 30px;
  background: var(--grey-50);
  overflow-y: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  flex: 2;
}

.search {
  display: flex;
  gap: 8px;
}

.tableContainer {
  margin-top: 16px;
  display: grid;
  gap: 16px;
}
