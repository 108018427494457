.row {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.children {
  flex: 1;
  overflow: hidden;
}
