.container {
  padding: 16px 24px;
  border: 1px;
  border-radius: 68px;
  height: 56px;
  gap: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.dark {
  background-color: black;
  color: white;
}

.dark:hover {
  background-color: rgb(40, 40, 40);
}

.dark:active {
  background-color: rgb(90, 90, 90);
}

.light {
  background-color: white;
  color: var(--grey-600);
  border: 1px solid var(--grey-300);
}

.light:hover {
  background-color: rgb(250, 250, 250);
}

.light:active {
  background-color: rgb(239, 239, 239);
}

.destructive {
  background-color: #d92d20;
  color: white;
}

.destructive:hover {
  background-color: #e45349;
}

.destructive:active {
  background-color: #e9756c;
}

.tertiary {
  background-color: #e8e8e8;
  color: var(--grey-900);
}

.tertiary:hover {
  background-color: #ededed;
}

.tertiary:active {
  background-color: #f0f0f0;
}
