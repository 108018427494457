.container {
  padding: 32px 0px 48px 0px;
  gap: 32px;
  background-color: var(--grey-50);
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow-y: scroll;
}

.title {
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  font-weight: 700;
}

.header {
  border-bottom: 1px solid var(--grey-200);
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 32px;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 720px;
}

.tab {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 0px 32px;
}

.tab h3 {
  border-bottom: 1px solid var(--grey-200);
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  padding-bottom: 20px;
}

.tab h4 {
  padding: 4px 0px;
}

.tab section {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.tab p {
  font-size: 16px;
  color: var(--grey-900);
  line-height: 24px;
  font-weight: 400;
}

.tab ul,
ol {
  padding-left: 24px;
  padding-top: 12px;
}

.tab li {
  padding: 4px 0px;
}

.stepContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.stepContainer img {
  border: 1px solid var(--grey-200);
}
