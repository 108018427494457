.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  width: 488px;
  padding-top: 24px;
}

.label {
  color: var(--grey-900);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.tableContainer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--grey-200);
}
