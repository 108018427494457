.currencyInput {
  display: flex;
  align-items: center;
  padding: 16px 16px;
  border-radius: 40px;
  border: 1px solid var(--grey-300);
  background: white;
  overflow: hidden;
  width: 30%;
  min-width: 120px;
}

.currencyInput input {
  all: unset;
  font-size: 14px;
  font-weight: 400;
  color: var(--base-black);
  line-height: 20px;
  width: 100%;
  text-align: start;
}

.currencySymbol {
  font-size: 18px;
  color: black;
  margin-left: 5px;
}
