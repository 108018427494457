.alertBox {
  padding: 16px;
  border-left: 4px solid;
  border-radius: 6px;
  font-size: 15px;
  line-height: 1.2;
  margin: 8px 0;
}

.error {
  background-color: #fde8e8;
  border-color: #e53e3e;
  color: #c53030;
}

.warning {
  background-color: #fef3c7;
  border-color: #d69e2e;
  color: #b7791f;
}

.success {
  background-color: #c6f6d5;
  border-color: #38a169;
  color: #2f855a;
}

.info {
  background-color: #bee3f8;
  border-color: #3182ce;
  color: #2b6cb0;
}
