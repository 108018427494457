.popoverContainer {
  position: relative;
  display: inline-block;
}

.popoverContent {
  position: absolute;
  bottom: 0;
  left: 100%;
  margin-left: 10px;
  width: 384px;
  border: 1px solid var(--grey-200);
  border-radius: 12px;
  background: white;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  z-index: 1000;
}

.popoverBody {
  padding: 10px;
}

.titleContainer {
  display: flex;
  padding: 24px 24px 0px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.title {
  color: var(--base-black);
  font-size: 16px;
  letter-spacing: -0.4px;
  width: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
}
