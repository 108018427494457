.popoverItemContainer {
  display: flex;
  padding: 8px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: white;
  gap: 12px;
}

.popoverItemContainer:hover {
  background: var(--grey-50);
  cursor: pointer;
}

.itemIcon {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--grey-50);
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.title {
  color: var(--grey-900);
  font-family: "Gotham";
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.label {
  color: var(--grey-500);
  font-family: "Gotham";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
