.container {
  display: flex;
  border: 1px solid var(--grey-200);
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.discountLabel {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  font-family: "Gotham";
}

.label {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  font-family: "Gotham";
}

.tag {
  padding: 4px 10px 4px 10px;
  border: 1px solid var(--grey-300);
  background-color: var(--grey-900);
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: "Gotham";
  color: white;
  cursor: pointer;
  width: fit-content;
}
