.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
}

.paginationNumbers {
  display: flex;
  gap: 10px;
  align-items: center;
}

.pageBtn,
.arrow {
  background: none;
  color: #475467;
  border: none;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px;
}

.pageBtn.active {
  background-color: white;
  color: black;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  border: 1px solid var(--grey-200);
  align-items: center;
  justify-content: center;
}

.pageBtn:disabled {
  cursor: default;
  opacity: 0.5;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-weight: 600;
}

.arrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
