.container {
  display: flex;
  width: 100%;
  position: relative;
}

.autoCompleteContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  top: 0px;
  width: 100%;
  padding-top: 30px;
  margin-top: 30px;
  z-index: 2;
  border: 1px solid var(--grey-200);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.autoCompleteItem {
  border-bottom: 1px solid var(--grey-200);
  padding: 10px 14px;
  width: 100%;
  z-index: 2;
}

.autoCompleteItem:hover {
  background-color: var(--grey-200);
  cursor: pointer;
}
