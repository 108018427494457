.container {
  display: flex;
  align-items: flex-start;
  border-radius: 40px;
  border: 1px solid var(--grey-300);
}

.input {
  display: flex;
  padding: 10px 0px 10px 14px;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  height: 45px;
  width: 75px;

  overflow: hidden;
  color: var(--grey-600);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: 0px;
}

.icon {
  display: flex;
  padding: 10px 14px 10px 4px;
  justify-content: space-between;
  align-items: center;
}
