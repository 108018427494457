.container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 488px;
}

.label {
  color: var(--grey-900);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.buttonContainer {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding-top: 32px;
  align-self: stretch;
  border-top: 1px solid var(--grey-200);
}

.accountingGiftCertificatesAddForm {
  display: flex;
  align-items: stretch;
}

.accountingGiftCertificatesAddFormContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.accountingGiftCertificatesAddFormBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0;
}

.accountingGiftCertificatesAddFormBlock label {
  font-size: 14px;
  font-weight: 500;
  color: var(--grey-600);
}

.addButton {
  margin-top: 20px;
  width: 50px;
  height: 50px;
  background-color: var(--base-black);
  border-radius: 12px;
  cursor: pointer;
}

.addButton:hover {
  background-color: var(--grey-700);
}

.accountingGiftCertificatesList {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.accountingGiftCertificatesList p {
  font-size: 16px;
  font-weight: 500;
  color: var(--grey-600);
}

.removeButton {
  width: 40px;
  height: 40px;
  padding: 5px;
  aspect-ratio: 1;
  background-color: #d92d20;
  border-radius: 12px;
  cursor: pointer;
}

.removeButton:hover {
  background-color: #e45349;
}
