.wrapper {
  height: 100%;
  width: 100%;
  background: var(--grey-50);
}

.container {
  height: 100%;
  gap: 40px;
  padding: 30px;
  background: var(--grey-50);
  overflow-y: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  flex: 1;
}

.filters {
  display: flex;
  gap: 8px;
}

.tableContainer {
  margin-top: 16px;
  display: flex;
}

.badge {
  padding: 0px 6px;
  border-radius: 8px;
  background-color: #f8f9fc;
  color: #61688f;
  border: 1px solid #d5d9eb;
  width: fit-content;
}

.saleModal {
  width: 50vw;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.saleModal h2 {
  font-size: 22px;
}

.saleModalContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 10px;
  overflow-y: auto;
  padding-top: 30px;
  padding: 30px 40px 30px 40px;
}

.saleModalFooter {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: 30px 20px;
}

.saleModalProduct {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
  gap: 30px;
}

.saleProductCard {
  border-bottom: 1px solid var(--grey-200);
  padding: 15px 15px;
}

.changePaymentMethodContainer {
  display: grid;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-200);
}

.changePaymentInput {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.changePaymentInput label {
  font-size: 15px;
  font-weight: 400;
}

.modalFooters {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
