.container {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  border-radius: 40px;
  border: 1px solid var(--grey-300);
  justify-content: space-between;
  width: 115px;
}

.content {
  display: flex;
  min-width: 40px;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  color: var(--base-black);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.button {
  border-radius: 32px;
  width: 40px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  cursor: pointer;
  background-color: var(--grey-200);
}

.button:active {
  background-color: var(--grey-300);
}
