.container {
  width: 100%;
  display: grid;
  gap: 10px;
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-radius: 40px;
  border: 1px solid var(--grey-300);
  gap: 8px;
  background: white;
  width: 100%;
  overflow: hidden;
}

.wrapper.error {
  border: 1px solid #e74c3c;
}

.wrapper textarea {
  resize: none;
  overflow: hidden;
  height: 100px;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: #475468;
}

.errorMessage {
  font-size: 12px;
  font-weight: 500;
  color: #e74c3c;
  text-align: center;
}

.inputContainer {
  all: unset;
  flex: 1;
  font-family: "Gotham";
  font-size: 14px;
  font-weight: 400;
  color: var(--base-black);
  line-height: 20px;
  width: 100%;
}

.leftIcon {
  width: 20px;
  aspect-ratio: 1;
}
