.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: white;
  gap: 50px;
}

.progressBar {
  min-width: 200px;
  height: 12px;
  width: 30%;
  background-color: #f1f5f9;
  border-radius: 20px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  background-color: black;
  transition: width 0.1s ease;
}

.logo {
  width: 60%;
  height: auto;
  mix-blend-mode: darken;
}

.progressText {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  color: black;
}
