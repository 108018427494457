.container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
}

.section {
  display: flex;
  flex-direction: column;
}

.section h2 {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.declared,
.theorical {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 20px 10px;
}

.declared:has(.collapsableContent.open),
.theorical:has(.collapsableContent.open) {
  border-bottom: 1px solid var(--grey-200);
  background-color: var(--grey-100);
}

.declared {
  color: red;
  border-top: 1px solid var(--grey-200);
  border-bottom: 1px solid var(--grey-200);
}

.collapsable {
  width: 100%;
  cursor: pointer;
}

.collapsable:hover {
  background-color: var(--grey-100);
}

.collapsableContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}

.collapsableContent.expanded {
  max-height: 500px;
}

.collapsableContent .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 10px 10px 10px 20px;
  padding-left: 20px;
  border-bottom: 1px solid var(--grey-200);
}

.item p {
  min-width: 30px;
}

.red {
  color: red;
}

.trigger {
  cursor: pointer;
}

.trigger:hover {
  background-color: var(--grey-100);
}

.footer {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
