.radioContainer {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #dcdfe5;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-weight: 400;
  font-size: small;
  user-select: none;
}

.radioContainer.selected {
  border: 2px solid black;
  background-color: var(--grey-50);
}

.input {
  appearance: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 1px solid #dcdfe5;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
}

.input:checked {
  border: 1px solid black;
}

.input:checked::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
