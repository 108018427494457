.container {
  display: flex;
  flex-direction: column;
  width: 84px;
  border-right: 1px solid var(--grey-200);
  height: 100%;
}

.barContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  height: 100%;
  align-items: center;
  padding: 16px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 0px 16px 16px 16px;
  align-items: center;
}

.footer:hover {
  background-color: var(--grey-200);
  cursor: pointer;
}

.footer:hover * {
  cursor: pointer;
}

.logoutButton {
  border-top: 1px solid var(--grey-200);
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 8px;
  width: 100%;
}

.logo {
  object-fit: contain;
}

.barButton {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
}

.label {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.iconContainer {
  padding: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  width: 52px;
  height: 52px;
}

.iconContainer:hover {
  background-color: var(--grey-200);
}

.icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.active {
  background-color: var(--grey-900);
  pointer-events: none;
}

.active:hover {
  background-color: black;
}

.divider {
  height: 1px;
  align-self: stretch;
  background: var(--grey-200);
}
