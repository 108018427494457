.container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tagContainer {
  display: flex;
  position: relative;
}

.tag {
  padding: 4px 10px 4px 10px;
  border: 1px solid var(--grey-300);
  background-color: white;
  border-radius: 6px;

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: "Gotham";
  color: var(--grey-900);
  cursor: pointer;
}

.tag:hover {
  background-color: rgb(250, 250, 250);
}

.selected {
  background-color: var(--grey-900);
  color: white;
  cursor: auto;
  padding-right: 28px !important;
}

.selected:hover {
  background-color: var(--grey-900);
}

.crossIcon {
  cursor: pointer !important;
  position: absolute;
  right: 4px;
  top: 4px;
}
