.spinner {
  border: 5px solid white;
  border-bottom-color: black;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.spinner.small {
  width: 24px;
  height: 24px;
}

.spinner.medium {
  width: 48px;
  height: 48px;
}

.spinner.large {
  width: 72px;
  height: 72px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.skeletonAnimation {
  overflow: hidden;
}

.skeletonAnimation::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  animation: skeleton 1.5s infinite;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

@keyframes skeleton {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.circular {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.rectangular {
  width: 100%;
  height: 100%;
}

.rounded {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.circular,
.rectangular,
.rounded {
  background: #dddddd;
  opacity: 0.7;
}
