.twoSidePanel {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.header {
  padding: 16px;
  border-bottom: 1px solid var(--grey-200);
}

.checkingPanel {
  display: flex;
  flex-direction: column;
  background-color: var(--grey-50);
  border-left: 1px solid var(--grey-200);
  height: 100%;
}

.inputContainer {
  flex: 1;
  overflow-y: scroll;
}

.referenceSection {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
}

.barcodeForm {
  display: flex;
  gap: 8px;
  width: 100%;
}

.referenceForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.discountSection {
  display: flex;
  flex-direction: column;
  gap: 10.67px;
  padding: 0px 21.33px;
}

.discoutTagContainer {
  border-top: 1px solid var(--grey-200);
  padding: 21.33px 0px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  font-size: 18px;
  line-height: 26px;
  color: #27243b;
  font-family: "Hanken Grotesk";
  font-weight: 600;
}

.footer {
  background-color: var(--grey-900);
  padding: 32px 24px;
  border-top: 1px solid var(--grey-200);
  gap: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.labelRow {
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
}

.totalLabel {
  font-weight: 400;
  line-height: 19.2px;
  color: white;
}

.priceLabel {
  font-weight: 700;
  font-size: 27px;
  color: white;
  line-height: 25.84px;
}
