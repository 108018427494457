.container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 488px;
}

.label {
  color: var(--grey-900);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.buttonContainer {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding-top: 32px;
  align-self: stretch;
  border-top: 1px solid var(--grey-200);
}
