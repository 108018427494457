.twoSideRow {
  display: flex;
  height: 100vh;
  justify-content: space-between;
}

.formPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 80px 0px;
  justify-content: space-between;
  overflow-y: auto;
  gap: 160px;
}

.header {
  margin: 6px 0px;
  width: 100%;
  padding: 0px 80px;
}

.imageContainer {
  position: relative;
  height: 100%;
  width: 41%;
  overflow-y: hidden;
}

.sidePanelImage {
  object-fit: cover;
  width: 100%;
}

@media screen and (max-width: 1068px) {
  .imageContainer {
    display: none;
  }
}

.title {
  width: 100%;
  text-align: center;
}

.form {
  padding: 0px 176px 0px 176px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer {
  padding: 0px 80px;
  display: flex;
  justify-content: space-between;
}

.footerColumn {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.footerRow {
  display: flex;
  gap: 8px;
  justify-content: right;
}

.footerLabel {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.errorLabel {
  color: #d92d20;
  font-size: 16px;
  line-height: 24px;

  width: 100%;
  text-align: center;
}
