.wrapper {
  width: 100%;
  min-width: 300px;
  z-index: 1;
  position: relative;
}

.calendar {
  z-index: 1;
  position: absolute;
  max-width: 300px;
  top: 100%;
  left: 0;
  display: grid;
  gap: 15px;
  width: 100%;
  padding: 15px;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
}

.top {
  bottom: 0%;
  top: unset;
}

.trigger {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 28px;
  border: 1px solid var(--grey-300);
  background: white;
  cursor: pointer;
}

.calendarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  text-align: center;
}

.calendarHeader button {
  background: none;
  border: none;
  cursor: pointer;
}

.calendarHeader h1 {
  font-size: 1rem;
  font-weight: bold;
}

.arrow {
  height: 15px;
  width: 15px;
}

.day {
  width: 14.28571%;
  aspect-ratio: 1;
  display: flex;
  text-align: center;
  user-select: none;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  border-radius: 100%;
  background-color: transparent;
}

.right,
.left {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: var(--grey-200);
}

.right {
  right: 0;
}

.innerDay {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.innerDay:hover:not(.selected):not(.between):not(.active) {
  background-color: var(--grey-200);
}

.days {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.labels {
  display: flex;
  justify-content: space-between;
}

.labels span {
  width: 14.28571%;
  aspect-ratio: 1;
  display: inline-block;
  text-align: center;
  user-select: none;
  line-height: 34px;
  position: relative;
  font-size: 16px;
}

.label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  cursor: initial;
}

.muted {
  color: rgba(0, 0, 0, 0.3);
}

.between {
  border-radius: 0;
}

.between {
  background-color: var(--grey-200);
}

.selected {
  background-color: black;
  color: white;
  border-radius: 100%;
}

.leftRounded {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.rightRounded {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    margin-top: -10px;
  }
  100% {
    margin-top: 0px;
  }
}

@keyframes fade_reverse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide_reverse {
  0% {
    margin-top: 0px;
  }
  100% {
    margin-top: -10px;
  }
}

.fade {
  animation-name: fade, slide;
  animation-duration: 200ms, 400ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
  animation-delay: 200ms, 0;
}

.fade_reverse {
  animation-name: fade_reverse, slide_reverse;
  animation-duration: 100ms, 200ms;
  animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
  animation-delay: 100ms, 0;
}

.yearSelect {
  appearance: none; /* Removes default styles */
  border: none;
  background: none;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  font-family: inherit;
  padding: 0;
  width: auto;
  cursor: pointer;
}

.yearSelect:focus {
  outline: none;
}
